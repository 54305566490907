window.addEventListener("scroll", function () {
    const header = document.getElementById('header');
    const headerImage = document.getElementById('image-header');
    const pos = window.scrollY;

    if (pos > 150) {
        header.style.boxShadow = "0 0 11px 0 rgb(18 40 58 / 69%)";
        header.style.background = "#3A5073";
        headerImage.style.width = "60px";
    } else {
        if (window.innerWidth > 992) {
            header.style.boxShadow = "none";
            header.style.background = "transparent";
            headerImage.style.width = "78px";
        } else {
            header.style.boxShadow = "none";
            header.style.background = "#3A5073";
            headerImage.style.width = "78px";
        }
    }
});
